<template>
  <div class="app-conten">
    <!-- 轮播图 -->
    <el-carousel height="37.5rem" v-if="carouselList.length > 0">
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <img :src="imgPic + item.fileUri" width="100%" height="600px" alt="" />
      </el-carousel-item>
    </el-carousel>

    <el-carousel height="37.5rem" v-else>
      <el-carousel-item v-for="(obj, index) in defalutList" :key="index">
        <img :src="obj.fileUri" width="100%" height="600px" alt="" />
      </el-carousel-item>
    </el-carousel>
    <!-- 二级导航 -->
    <commonNavigation></commonNavigation>
    <div class="content_box" v-for="(item, index) in companyList" :key="index">
      <div class="item_Son">
        <div class="h2">{{ item.title }}</div>
        <div class="content_items" v-if="isDatePageviews == '0'">
          <div>
            时间：<span style="color: #c1c1c1">{{ item.updateTime }}</span>
          </div>
          <div style="margin-left: 20px">
            浏览次数：<span style="color: #c1c1c1">
              {{ item.viewsNumbers }}</span
            >
          </div>
          <div style="margin-left: 20px">
            信息来源 <span style="color: #c1c1c1">{{ item.source }}</span>
          </div>
        </div>
        <div class="linethro"></div>
        <div class="paramContent" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  newsListpAGE,
  slideshowList,
  companyArticleDetails,
} from '@/api/index.js';
import commonNavigation from '@/layout/components/commonNavigation';
export default {
  components: {
    commonNavigation,
  },
  data() {
    return {
      imgPic: '',
      queryParams: {
        pageNum: 1, //当前页数
        pageSize: 10, //每页10条
        columnId: '',
      },
      defalutList: [
        {
          fileUri: require('@/assets/allImage/defaultCarouselChart/gongsigaikuang@1x.png'),
        },
      ],
      carouselList: [], //公共轮播图
      companyList: [],
    };
  },
  created() {
    this.imgPic = process.env.VUE_APP_BASE_URL;
  },
  mounted() {
    // this.companyList = this.$store.state.companyList;
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal, oldVal) {
        this.isDatePageviews = newVal.isDatePageviews; // 0是   1否
        this.getList(newVal);
        this.rotationChart(newVal);
      },
    },
  },
  methods: {
    async getList(newVal) {
      this.companyList = [];
      this.queryParams.columnId = newVal.id;
      const res = await newsListpAGE(this.queryParams);
      if (res.data.code == 200) {
        if (res.data.data.records && res.data.data.records.length > 0) {
          this.queryParams.id = res.data.data.records[0].id;
          const detailsAr = await companyArticleDetails(this.queryParams);
          if (detailsAr.data.code == 200) {
            if (detailsAr.data.data && detailsAr.data.data.length > 0) {
              this.companyList.push(detailsAr.data.data[0]);
            }
          }
        }
      }
    },
    // 轮播图
    async rotationChart(newId) {
      this.carouselList = [];
      const res = await slideshowList(newId.id);
      if (res.data.code == 200) {
        if (res.data.data && res.data.data.length > 0) {
          this.carouselList = res.data.data;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app-conten {
  width: 100%;
  .content_box {
    width: 100%;

    .item_Son {
      width: 70%;
      height: 100%;
      margin: 0 auto;
      .content_items {
        width: 100%;
        height: 35px;
        display: flex;
        font-size: 12px;
        align-items: center;
        justify-content: center;
      }
      .linethro {
        width: 100%;
        height: 1px;
        background-color: black;
        margin-top: 10px;
      }
      .h2 {
        text-align: center;
        margin-top: 1.25rem;
        font-size: 16px;
        font-weight: 600;
      }
      .paramContent {
        line-height: 2.1875rem;
        ::v-deep img {
          max-width: 90%;
        }
      }
    }
  }
}
</style>
